import React, { useEffect, useRef, useState } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, ifNotProp, prop, switchProp } from 'styled-tools';
import { faChevronDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLayoutContext } from 'context/LayoutContext';
import warningIcon from 'images/warning-icon.svg';
import infoIcon from 'images/info-icon.svg';
import { calcPercent } from 'utils/functions';

const XLARGE = 80;
const LARGE = 77;

const AlertContainer = styled.div``;

const TastyAlert = styled(Alert)`
  align-items: center;
  display: flex;
  font-family: ${prop('theme.fonts.default')};
  height: 3rem;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1106;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    padding-top: 2rem !important;
    padding-bottom: 7rem !important;
  }

  &&& {
    border: none;
    border-radius: 0;
    margin: 0;
    padding-right: 3.5rem;

    .close {
      color: ${prop('theme.colors.white')};
      opacity: 1;

      &:hover {
        color: ${prop('theme.colors.white')};
      }
    }

    ${switchProp('$type', {
      'warning': css`
        background-color: ${prop('theme.colors.primaryRed')};
        color: ${prop('theme.colors.white')};
    `,
      'info': css`
        background-color: ${prop('theme.colors.darkGray')};
        color: ${prop('theme.colors.white')};
      `,
    })}
  }

  ${ifProp(
    '$seeMore',
    css`
      align-items: flex-start;
      height: auto;
      z-index: 1110;
    `
  )}
`;

const AlertIcon = styled.img`
  height: 1.25rem;
  margin-right: 1rem;
`;

const TextSection = styled.div`
  align-items: center;
  display: flex;
  font-family: ${prop('theme.fonts.default')};
  flex-wrap: wrap;
  height: 1.5rem;
  width: 100%;
`;

const AlertCopy = styled.p`
  color: ${prop('theme.colors.white')};
  margin-bottom: 0;
  margin-right: 1rem;
`;

const AlertCta = styled.a`
  color:  ${prop('theme.colors.white')};
  font-family: ${prop('theme.fonts.condensed')};
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: ${prop('theme.colors.white')};
    text-decoration: none;
  }

  svg {
    margin-left: 0.5rem;
  }
`;

const Toggle = styled.button`
  background: transparent;
  border: none;
  color: ${prop('theme.colors.white')};
  cursor: pointer;
  font-family: ${prop('theme.fonts.condensed')};
  font-weight: 600;
  height: 1.5rem;
  margin-left: 1rem;
  margin-right: 0.875rem;
  min-width: fit-content;
  padding: 0;
  text-transform: uppercase;
  visibility: hidden;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    margin-left: 2rem;
    margin-right: 0;
  }

  &:focus {
    outline: none;
  }

  ${ifProp(
    '$displayToggle',
    css`
      visibility: visible;
    `
  )}

  span {
    min-width: 3.75rem;
    margin-right: 0.875rem;
    text-align: left;

    @media (max-width: ${prop('theme.breakpoints.sm')}) {
      display: none;
    }
  }

  svg {
    transition-duration: 0.3s;
    transition-property: transform;

    ${ifProp(
      '$seeMore',
      css`
        align-items: flex-start;
        transform: rotate(180deg);
      `
    )}
  }
`;

function AlertBanner({ sitewide = true, type = 'warning' }) {
  const { state: layoutContextState, setShowAlert } = useLayoutContext();
  const [seeMore, setSeeMore] = useState(false);
  const [alertSession, setAlertSession] = useState(false);
  const [percentFull, setPercentFull] = useState();
  const checkPercent = XLARGE;
  const textSectionRef = useRef(null);
  const { showAlert } = layoutContextState;

  useEffect(() => {
    const alertSessionStorage = sessionStorage.getItem('alert_visibility');

    if (alertSessionStorage === 'hidden') {
      setShowAlert(false);
    } else {
      setShowAlert(false);
    }
  }, [showAlert])

  useEffect(() => {
    let parent;
    let child;

    // Checking the width of the alert message section on component mount
    // and determining what percentage of the the parent div the copy itself
    // is filling
    if (textSectionRef.current) {
      parent = textSectionRef.current.offsetWidth;
      child = textSectionRef.current.firstChild.offsetWidth;
      setPercentFull(calcPercent(child, parent));
    }

    // Re-checking the width of the alert message section if the user resizes
    // the screen and determining what percentage of the the parent div the
    // copy itself is filling
    function handleResize() {
      if (textSectionRef.current) {
        parent = textSectionRef.current.offsetWidth;
        child = textSectionRef.current.firstChild.offsetWidth;
        setPercentFull(calcPercent(child, parent));
      }
    }

    window.addEventListener('resize', handleResize);
  }, [textSectionRef]);

  const toggleAlert = () => {
    sessionStorage.setItem('alert_visibility', 'hidden');
    setShowAlert(!showAlert);
  }

  return (
    <AlertContainer>
      <TastyAlert
        fade={false}
        $type={type}
        $seeMore={seeMore}
        isOpen={showAlert}
        $sitewide={sitewide}
        toggle={toggleAlert}
      >
        <div>
          <AlertIcon alt={`${type} icon`} src={type === 'info' ? infoIcon : warningIcon} />
        </div>
        <TextSection>
          <AlertCopy>
            Important: tastyworks has changed its name to tastytrade.
          </AlertCopy>
          <AlertCta href='https://support.tastytrade.com/support/s/solutions/articles/43000694817' target='_blank'>
            Learn more
            <FontAwesomeIcon icon={faArrowRight} />
          </AlertCta>
        </TextSection>
        <Toggle
          $displayToggle={percentFull >= checkPercent}
          onClick={() => setSeeMore(!seeMore)}
          $seeMore={seeMore}
        >
          <span>{seeMore ? 'See Less' : 'See More'}</span>
          <FontAwesomeIcon $seeMore={seeMore} icon={faChevronDown} />
        </Toggle>
      </TastyAlert>
    </AlertContainer>
  );
}

export default AlertBanner;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { isStyledComponent, css } from 'styled-components';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import closeIcon from 'images/icon-close.svg';
import { ifProp, prop } from 'styled-tools';

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 0;
  }

  .modal-header {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
  }

  .modal-header,
  .modal-footer {
    border: none;
  }

  .modal-footer {
    justify-content: space-evenly;
  }

  .modal-title {
    align-items: center;
    display: flex;
    font-family: ${props => props.theme.fonts.condensed};
    font-size: 1.375rem;
    font-weight: 700;
    justify-content: space-between;
    text-transform: uppercase;
    width: 100%;
  }

  .modal-body {
    font-size: 1.125rem;
    font-weight: 300;
  }

  ${ifProp('dark', css`
    .modal-content {
      color: ${prop('theme.colors.white')};
      background-color: ${prop('theme.colors.black')};
    }

    .form-group {
      label {
        color: #838a8a;
      }
    }

    input.form-control {
      margin-bottom: 20px;
      border: 1px solid #444;
      background-color: #212626;
      color: #999998;

      &:focus {
        color: #fff;
      }
    }
  `)}
`;

const CloseIcon = styled.span`
  background-image: url(${closeIcon});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 1.25rem 1.25rem;
  cursor: pointer;
  display: block;
  height: 2.5rem;
  width: 2.5rem;
`;

const propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.node,
  children: PropTypes.node
};

const defaultProps = {
  isOpen: false,
  toggle: undefined,
  title: undefined,
  children: undefined
};

function hasModalBodyInChildren(children) {
  let matched = false;
  React.Children.forEach(children, child => {
    if (child && child.type === ModalBody) {
      matched = true;
    } else if (child && isStyledComponent(child.type) && child.type.target === ModalBody) {
      matched = true;
    }
  });

  return matched;
}

function TastyModal({ isOpen, toggle, title, children, ...props }) {
  const isBodyInChildren = hasModalBodyInChildren(children);

  return (
    <StyledModal isOpen={isOpen} toggle={toggle} {...props}>
      {title && (
        <ModalHeader>
          {title}
          {toggle && <CloseIcon onClick={toggle} />}
        </ModalHeader>
      )}
      {isBodyInChildren ? children : (
        <ModalBody>
          {children}
        </ModalBody>
      )}
    </StyledModal>
  );
}

TastyModal.propTypes = propTypes;
TastyModal.defaultProps = defaultProps;

export default TastyModal;

import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth, innerHeight } = window;

  return {
    width: innerWidth,
    height: innerHeight
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });

  const handleResize = () => {
    if (typeof window !== 'undefined') {
      setWindowDimensions(getWindowDimensions());
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default useWindowDimensions;
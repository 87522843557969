import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import 'typeface-open-sans'
import styled, { css } from 'styled-components';
import { Container, Row, Col } from 'reactstrap'
import TastyButton from '../TastyButton'
import LegalFooter from './LegalFooter'
import externalLinks from 'utils/externalLinks'
import ExternalLink from 'components/ExternalLink';
import BaseLogo from 'components/Logo';
import { ifProp, prop } from 'styled-tools';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Logo = styled(BaseLogo)`
  &&& {
    padding-right: 3rem;
    padding-top: 0.375rem;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      img.tw-logo {
        max-width: 8.635rem;
      }
    }
  }
`;

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.colors.black};
  padding-top: 1.875rem;

  .container-fluid {
    max-width: 1170px;
    padding: 0;
  }

  .phone-icon {
    width: 1.5rem;
  }

  hr {
    border-top: 1px solid ${props => props.theme.colors.lightGray};
  }

  .h1 {
    margin-top: 0.625rem;
    color: ${props => props.theme.colors.gray};
    font-size: 1.5em;
    line-height: 1.5em;
    letter-spacing: 0.03em;
    font-family: 'Open Sans';
    font-weight: 700;
    text-transform: uppercase;
  }

  img.logo {
    width: 9.375rem;
    margin-bottom: 1.875rem;
  }
`;

const FooterContainer = styled(Container)`
  padding-bottom: 2rem;
`;

const IconContainer = styled.div`
  background: ${prop('theme.colors.primaryRed')};
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;

  svg {
    width: .5rem;
    height: .5rem;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const FooterCol = styled(Col)`
  &&& {
    .h1 {
      margin-top: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      max-width: 19.5%;
    }

    &:first-child {
      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        max-width: 22%;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    ${ifProp('$divider', css`
      border-right: 1px solid white;
      padding-right: 1.25rem;
    `)}
  }
`;

const AddressDetails = styled.div`
  color: ${prop('theme.colors.white')};
  margin-top: 0.5rem;

  p {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 0;
  }
`;

const FooterLinkList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  a {
    font-family: 'Open Sans';
    color: ${props => props.theme.colors.white};
    font-size: 0.875rem;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colors.primaryRed};
      text-decoration: none;
    }
  }

  li {
    padding: 0;
    margin-bottom: 0.625rem;
  }
`;

const MutedParagraph = styled.p`
  font-family: ${prop('theme.fonts.default')};
  font-size: 0.75em;
  color: ${props => props.theme.colors.gray};
  line-height: 1.5em;
`;

const AddressHeader = styled.p`
  && {
    font-size: 0.875rem;
  }
`;

const OfficeAddressHeader = styled.p`
  && {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`

const SocialIcons = styled.div`
  margin-top: 0.5rem;

  a {
    &:not(:last-child) {
      margin-right: 0.75rem;
    }
  }
`;

const NoShowText = styled.span`
  font-size: 0;
  width: 0;
  height: 0;
`;

class Footer extends PureComponent {
  render() {
    const socialLinks = [
      {
        icon: faFacebookF,
        link: 'https://www.facebook.com/tastytradeAU',
        alt: 'tastytrade Australia Facebook'
      },
      {
        icon: faTwitter,
        link: 'https://twitter.com/tastytradeau',
        alt: 'tastytrade Australia Twitter'
      },
      {
        icon: faLinkedinIn,
        link: 'https://www.linkedin.com/company/tastytrade-australia',
        alt: 'tastytrade Australia LinkedIn'
      },
    ]

    return (
      <StyledFooter>
        <FooterContainer>
          <Row className='justify-content-between'>
            <FooterCol sm={12} lg='auto' $divider className='mb-5 mb-lg-0'>
              <Logo inverse fullWidth />
              <AddressDetails>
                <OfficeAddressHeader>Office Address</OfficeAddressHeader>
                <p>L 17, 123 Pitt St</p>
                <p>Sydney NSW 2000</p>
                <ContactDetails>
                  <IconContainer>
                    <FontAwesomeIcon icon={faPhoneAlt} color='white' size='lg' />
                  </IconContainer>
                  <p className='mt-2'><a href='tel:+610283171402' className='text-white'>+61 02 8317 1402</a></p>
                </ContactDetails>
                <ContactDetails>
                  <IconContainer>
                    <FontAwesomeIcon icon={faEnvelope} color='white' size='lg' />
                  </IconContainer>
                  <p>
                    <a href='mailto:support@tastytrade.au' className='text-white'>
                      support@tastytrade.au
                    </a>
                  </p>
                </ContactDetails>
                <SocialIcons>
                  {socialLinks.map((socialLink, index) => (
                    <a href={socialLink.link} key={index} target='blank'>
                      <NoShowText>{socialLink.link}</NoShowText>
                      <FontAwesomeIcon icon={socialLink.icon} color='white' size='lg' alt={socialLink.alt}/>
                    </a>
                  ))}
                </SocialIcons>
              </AddressDetails>
            </FooterCol>
            <FooterCol sm={12} md={6} lg='auto' className='mb-5 mb-lg-0'>
              <h2 className='h1'>Company</h2>
              <FooterLinkList>
                <li>
                  <Link to='/about-us'>About Us</Link>
                </li>
                <li>
                  <a href={externalLinks.tastylive} target='_blank'>
                    tastylive
                  </a>
                </li>
                <li>
                  <a href={externalLinks.finraBrokerCheck} target='_blank'>
                    FINRA BrokerCheck
                  </a>
                </li>
                <li>
                  <a href={externalLinks.asicRegistration} target='_blank'>
                    ASIC Check
                  </a>
                </li>
              </FooterLinkList>
            </FooterCol>
            <FooterCol sm={12} md={6} lg='auto' className='mb-5 mb-lg-0'>
              <h2 className='h1'>Documents</h2>
              <FooterLinkList>
                <li>
                  <Link to='/forms-and-agreements'>Forms &amp; Agreements</Link>
                </li>
                <li>
                  <Link to='/disclosures'>Disclosures</Link>
                </li>
                <li>
                  <Link to='/external-complaints-policy'>External Complaints Policy</Link>
                </li>
              </FooterLinkList>
            </FooterCol>
            <FooterCol sm={12} md={6} lg='auto' className='mb-5 mb-lg-0'>
              <h2 className='h1'>Specifics</h2>
              <FooterLinkList>
                <li>
                  <ExternalLink
                    primary
                    displayUrl='http://tastytrade.com'
                    disclaimerPresent={true}
                    href='https://tastytrade.com/commissions-and-fees/'
                  >
                    Commissions & Fees
                  </ExternalLink>
                </li>
                <li>
                  <Link to='/security'>Security</Link>
                </li>
                <li>
                  <Link to='/how-to-fund' target='_blank'>How To Fund</Link>
                </li>
              </FooterLinkList>
            </FooterCol>
            <FooterCol sm={12} md={6} lg='auto' className='mb-5 mb-lg-0'>
              <h2 className='h1'>Last Chance!</h2>
              <MutedParagraph>
                ...on this page anyway. click below to open your tastytrade account
                now!
              </MutedParagraph>
              <TastyButton footer href={externalLinks.openAnAccount}>Open an Account</TastyButton>
            </FooterCol>
          </Row>
        </FooterContainer>
        <hr />
        <LegalFooter />
      </StyledFooter>
    )
  }
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: '',
};

export default Footer;

import styled from 'styled-components';
import menuIcon from '../../../images/icon-menu.svg';
import menuIconOpen from '../../../images/icon-menu-open.svg';

const MobileNavbar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    justify-content: normal;
    padding: 0;
    width: auto;
  }

  .navbar-toggler {
    border: 0;
    min-width: 9.125rem;
    padding: 0;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      min-width: 7.5rem;
    }

    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      min-width: auto;
    }

    &:focus {
      outline: none;
    }

    &.active {
      .navbar-toggler-icon {
        background-image: url(${menuIconOpen});
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.primaryRed};
      }
    }
  }

  .navbar-toggler-icon {
    background-image: url(${menuIcon});
    background-position: 50% 50%;
    background-size: 1.375rem;
    display: block;
    padding: 2rem;
  }
`;

export default MobileNavbar;

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { ifNotProp, prop } from 'styled-tools';
import GlobalStyle from 'styles/GlobalStyle';
import Header from './Header';
import Footer from './Footer';
import theme from '../styles/theme';
import background from '../images/main-background.png';
import 'typeface-open-sans-condensed';
import 'typeface-open-sans';
import Cookies from 'js-cookie';

const propTypes = {
  hideBackground: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  hideBackground: false,
}

const StyledLayout = styled.div`
  ${ifNotProp('$hideBackground', css`
    background-image: url(${background}), url(${background}), url(${background});
    background-repeat: no-repeat;
    background-position: top -3.125rem left -8.5625rem, center right -11.125rem,
      bottom -6.25rem left -15.25rem;
    background-attachment: fixed;
  `)}

  color:  ${prop('theme.colors.secondaryBlack')};
  font-family: ${prop('theme.fonts.condensed')};

  @media (max-width: 1024px) {
    background-position: top -9.125rem left -23.5625rem, center right -22.125rem,
      bottom -6.25rem left -25.25rem;
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    background-position: top -20.125rem left -31.5625rem, center right -31.125rem,
      bottom -11.25rem left -30.25rem;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    background-position: top 3.875rem left 11.4375rem, center right 10.875rem,
    bottom 2.75rem left -37.25rem;
  }
`;

const CutoffPrevention = styled.div`
  padding-top: 1px;
`;

const Layout = ({ hideBackground = false, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const promoBarCookie = Cookies.get('showPromoBar');

  const [showPromoBar, setShowPromoBar] = useState(typeof promoBarCookie !== 'undefined' ? promoBarCookie !== 'false' && promoBarCookie !== false : true);

  const showPromoBarCallback = useCallback(
    (value) => {
      setShowPromoBar(value);
      Cookies.set('showPromoBar', value);
    },
    [],
  );

  return (
    <>
      <CutoffPrevention/>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <StyledLayout $hideBackground={hideBackground}>
            <Header showPromoBar={false} setShowPromoBar={showPromoBarCallback} />
            {React.Children.map(children, child =>
              React.cloneElement(child, { showPromoBar: false })
            )}
            <Footer />
          </StyledLayout>
        </>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;

import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { Link } from 'gatsby';

const TastyButton = styled.button.attrs(props => ({
  as: (props.to && Link) || (props.href && 'a'),
}))`
  background-color: ${props => props.theme.buttons.default.backgroundColor};
  border: ${props => ifProp('authenticated', `3px solid ${props.theme.colors.black}`, 'none')};
  color: ${props => props.theme.buttons.default.color};
  cursor: pointer;
  font-family: ${prop('theme.fonts.condensed')};
  font-size: 1em;
  font-weight: bold;
  min-width: 9.125rem;
  padding: 1em;
  text-transform: uppercase;
  width: ${ifProp('block', '100%', 'fit-content')};
  display: ${ifProp('block', 'block', 'inline-block')};
  text-align: center;

  // Specificity fix when rendered as an "a" tag.
  a&& {
    color: ${props => props.theme.colors.white};
    font-weight: bold;
    text-decoration: none;
  }

  && {
    &:hover {
      background: ${props => props.theme.colors.primaryRed};
      color: ${props => props.theme.colors.white};
    }

    ${ifProp(
      'primary',
      css`
        color: ${props => props.theme.buttons.primary.color};
        background: ${props => props.theme.buttons.primary.backgroundColor};
      `
    )}

    &:hover {
      background-color: ${props => props.theme.colors.primaryBlack};
    }

    ${ifProp(
      'header',
      css`
        background: #f30000;
        color: ${props => props.theme.buttons.header.color};
        display: none;
        font-family: ${prop('theme.fonts.default')};
        padding: 0.375em 1.5em;

        a& {
          font-weight: 600;
        }

        &:hover {
          background: ${props => props.theme.colors.black};
        }

        @media (min-width: ${props => props.theme.breakpoints.lg}) {
          display: block;
        }
      `
    )}

    ${ifProp(
      'footer',
      css`
        color: ${props => props.theme.buttons.footer.color};
        background: ${props => props.theme.buttons.footer.backgroundColor};

        &:hover {
          background: ${props => props.theme.colors.primaryBlack};
        }
      `
    )}
  }
`;

export default TastyButton;

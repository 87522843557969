import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { NavItem, NavLink } from 'reactstrap';
import tastyworksApi from 'utils/tastyworksApi';
import externalLinks from 'utils/externalLinks';

Login.propTypes = {
  data: PropTypes.shape({
    authenticated: PropTypes.bool,
  }),
  item: PropTypes.object,
  itemExternal: PropTypes.bool,
  itemInternal: PropTypes.string,
};

function Login({ data, item, itemExternal, itemInternal }) {
  const externalLink = get(item, 'link.[0].linkUrl.href');
  const internalLink = itemInternal || '';

  return (
    <NavItem>
      {data.authenticated ? (
        <NavLink
          href='#'
          onClick={() => {
            tastyworksApi.logout().then(() => set({ authenticated: false }));
          }}
        >
          Log Out
        </NavLink>
      ) : (
        <NavLink 
          href={itemExternal ? externalLink : undefined}
          to={!itemExternal ? internalLink : undefined}
        >
          {item.linkText}
        </NavLink>
      )}
    </NavItem>
  );
}

export default Login;
import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import externalLinks from 'utils/externalLinks';
import financialServicesGuide from 'documents/tastytrade-aus-fsg.pdf';
import privacyPolicy from 'documents/tastytrade-aus-privacy-policy.pdf';
import { prop } from 'styled-tools';


const FooterContainer = styled(Container)`
  padding-bottom: 2rem;
  padding-top: 2rem;
`;

const LegalText = styled.p`
  font-family: ${prop('theme.fonts.default')};
  font-size: 0.75em;
  color: ${props => props.theme.colors.gray};
  line-height: 1.5em;

  a {
    color: ${props => props.theme.colors.primaryRed};

    &:hover {
      color: ${props => props.theme.colors.gray};
      text-decoration: none;
    }
  }

  .divider {
    padding: 0 0.1875rem;
  }
`;

const Copyright = styled.div`
  > div {
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.default};
    font-size: 0.875rem;
    font-weight: 700;
  }

  ${LegalText} {
    margin-bottom: 0;
  }
`;

const LegalFooter = () => (
  <FooterContainer>
    <Row>
      <Col md={9}>
        <LegalText>
          tastytrade Australia Pty Ltd (ABN 61 623 542 969, AFSL 508867) (<strong>tastytrade Australia</strong>) is
          authorised under Australian financial services law to provide general financial product advice; arrange for
          the issue of, derivatives, securities and managed investment schemes to retail clients; and issue standard
          margin lending facilities. <strong>tastytrade</strong> Australia is a wholly-owned subsidiary of tastylive, Inc
          (<strong>tastylive</strong>),
          a company established as a Delaware C-Corporation located in Chicago, Illinois in the United States.
        </LegalText>
        <LegalText>
          <strong>tastytrade</strong> Australia issues standard margin lending facilities for Australian residents only (<strong>Margin
          Lending</strong>). <strong>tastytrade</strong> Australia also has a referral arrangement with <strong>tastytrade</strong>, Inc (<strong>tastytrade
          U.S.</strong>) a U.S.
          registered introducing broker servicing the self-directed investor. <strong>tastytrade</strong> U.S is regulated by FINRA
          (Financial Industry Regulatory Authority), SEC (Securities and Exchange Commission), NFA (National Futures
          Association) and the CFTC (Commodity Futures Trading Commission) and is a member of FINRA, NFA and SIPC
          (Securities Investor Protection Corporation). Upon approval by <strong>tastytrade</strong> U.S. and its clearing firm Apex
          Clearing Corporation (<strong>Apex</strong>), a registered U.S. clearing firm, Australian investors can open a
          trading
          account with <strong>tastytrade</strong> U.S. <strong>tastytrade</strong> U.S. and Apex also are service providers for Margin Lending.
        </LegalText>
        <LegalText>
          Any financial services provided in Australia are provided by or on behalf of <strong>tastytrade</strong> Australia under its Australian Financial Services Licence number 508867.
          Please see the <strong>tastytrade</strong> Australia{' '}<a href={financialServicesGuide} target='_blank'>Financial Services
          Guide</a> for more detail about the financial services it provides. <strong>tastytrade</strong> recommends
          that you review our{' '}<a href={externalLinks.productDisclosureStatement} target='_blank'>Product Disclosure Statement</a>
          {' '}to assess if this product is appropriate, and if you are unsure please seek financial
          advice from a licensed advisor.
        </LegalText>
        <LegalText>
          <strong>tastytrade</strong> Australia collects, uses and discloses personal information in accordance with
          the{' '}<a href='https://www.oaic.gov.au/privacy-law/privacy-act/australian-privacy-principles' target='_blank'>Australian Privacy Principles</a>.
          By using this website, you are taken to have agreed to the terms of <strong>tastytrade</strong> Australia’s Privacy Policy, a copy of which is available{' '}
          <a href={privacyPolicy} target='_blank'>here</a>.
        </LegalText>
      </Col>
      <Col md={3}>
        <Copyright>
          <div>&copy; 2017-{new Date().getFullYear()} tastytrade, Inc.</div>
          <LegalText>
            Copyrights, logos, and trademarks are property of tastytrade, Inc.
            All rights reserved. tastytrade, Inc., member
          </LegalText>
          <LegalText>
            <a
              href={externalLinks.financialIndustryReg}
              rel='noopener noreferrer'
              target='_blank'
            >
              FINRA
            </a>
            <span className='divider'>|</span>
            <a
              href={externalLinks.securitiesInvestor}
              rel='noopener noreferrer'
              target='_blank'
            >
              SIPC
            </a>
            <span className='divider'>|</span>
            <a
              href={externalLinks.nationalFuturesAssoc}
              rel='noopener noreferrer'
              target='_blank'
            >
              NFA
            </a>
            <span className='divider'>|</span>
            <a
              href={externalLinks.asicGov}
              rel='noopener noreferrer'
              target='_blank'
            >
              ASIC
            </a>
          </LegalText>
        </Copyright>
      </Col>
    </Row>
  </FooterContainer>
);

export default LegalFooter;

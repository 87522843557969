import { Nav } from 'reactstrap';
import styled from 'styled-components';

const NavList = styled(Nav)`
  align-items: center;
  display: flex;
  height: 100vh;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    height: auto;
    margin-right: -1rem;
  }

  & > span,
  .nav-link {
    color: ${props => props.theme.colors.white};
    font-weight: 700;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      color: ${props => props.theme.colors.black};
      margin: 0.5em;
    }
  }

  .dropdown-link {
    &:hover {
      color: ${props => props.theme.colors.primaryRed};
    }
  }

  a {
    text-decoration: none;
    text-transform: uppercase;

    &.nav-link {
      @media (max-width: ${props => props.theme.breakpoints.lg}) {
        font-size: 1.125rem;
        padding-left: 1rem;
      }
    }
  }

  button {
    margin-left: 0.5em;
  }

  .nav-item {
    width: 100%;

    .nav-link {
      &:hover {
        color: ${props => props.theme.colors.primaryRed};
      }
    }

    &.mobile {
      display: list-item;

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        display: none;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      width: auto;
    }
  }
`;

export default NavList;

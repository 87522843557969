import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, prop } from 'styled-tools';
import ausFlagIconImg from 'images/logo/australia-flag-icon.png';
import logoOnWhite from 'images/tt-aus-logo.png';
import logoOnBlack from '../../images/tt-aus-white.png';

const OuterWrap = styled.div`
  display: block;
  margin-right: 0;
  width: 100%;

  ${ifProp('$header', css`
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 75%;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      margin-right: 1rem;
    }
  `)}
`;

const LogoWrapper = styled.div`
  display: flex;

  img.tw-logo {
    width: 100%;

    ${ifNotProp('$fullWidth', css`
      max-width: 8.75rem;

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        max-width: 12.5rem;
      }
    `)}
  }

  small {
    color: ${props => props.theme.colors.secondaryBlack};
    display: block;
    font-size: 0.875rem;
  }
`;

const AustraliaFlagIcon = styled.img.attrs(props => ({
  src: ausFlagIconImg,
  alt: 'Australian National Flag'
}))`
  max-width: 2.25rem;
  margin-right: 0.5rem;
`;

const LogoTagline = styled.p`
  &&& {
    font-family: ${prop('theme.fonts.default')};
    color: ${ifProp('$inverse', prop('theme.colors.white'), prop('theme.colors.blackGray'))};
    font-size: 0.75rem;
    font-weight: 700;
    margin: -0.375rem 0 0;
    text-align: right;
    
    ${ifProp('$header', css`
      max-width: 9rem;
    `)};
  }
`;

const propTypes = {
  showFlag: PropTypes.bool,
  inverse: PropTypes.bool,
  header: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  showFlag: false,
  inverse: false,
  header: false,
  fullWidth: false,
  className: undefined,
};

function Logo({ showFlag = false, inverse = false, header = false, fullWidth = false, className }) {
  return (
    <OuterWrap className={className} $header={header}>
      <LogoWrapper $fullWidth={fullWidth}>
        <div className='text-lg-right'>
          <img className='tw-logo' src={inverse ? logoOnBlack : logoOnWhite} alt='tastytrade Australia' />
        </div>
      </LogoWrapper>
    </OuterWrap>
  );
}

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;

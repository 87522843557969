export default {
  activeTraderInterface:
    'https://support.tastytrade.com/support/s/solutions/articles/43000435215',
  curveAnalysis:
    'https://support.tastytrade.com/support/s/solutions/articles/43000472386',
  quickRoll:
    'https://support.tastytrade.com/support/s/solutions/articles/43000435416',
  percentOfProfitLimitOrders:
    'https://support.tastytrade.com/support/s/solutions/articles/43000435423',
  asicGov:
  'https://asic.gov.au/',
  etfEquivalentFuturesDelta:
    'https://support.tastytrade.com/support/s/solutions/articles/43000435422',
  auTradeTheSmalls: 'https://info.tastyworks.com.au/tradethesmalls',
  auCfd: 'https://info.tastyworks.com.au/CFD ',
  auEbook: 'https://info.tastylive.com/ebook.au',
  smallExchangeOffer: 'https://info.tastytrade.com/the-small-exchange?utm_source=tastytrade&utm_medium=alert_HP&utm_campaign=offer',
  smallExchangeProducts: 'https://info.tastytrade.com/tradethesmalls?utm_source=tastytrade&utm_medium=banner&utm_campaign=small_exchange_2020&utm_content=homepage',
  smallsProductsNoCampaign: 'https://info.tastytrade.com/tradethesmalls',
  homeRobustIras: 'https://info.tastytrade.com/2019-offer?utm_source=home_page&utm_medium=sub_banner&utm_campaign=2019_offer',
  tastylive: 'https://www.tastylive.com/?utm_source=tastytrade&utm_medium=inspiration&utm_campaign=refreshed',
  standardizedOptionsRisks: 'https://assets.tastyworks.com/production/documents/characteristics_and_risks_of_standardized_options.pdf',
  futuresRiskDisclosure: 'https://assets.tastyworks.com/production/documents/futures_exchange_traded_options_risk_disclosure_agreement.pdf',
  financialIndustryReg: 'http://www.finra.org/',
  securitiesInvestor: 'https://www.sipc.org/',
  coinBible: 'https://info.tastytrade.com/crypto-bible',
  nationalFuturesAssoc: 'https://www.nfa.futures.org/',
  openAnAccount: 'https://open.tastytrade.com/signup?referralCode=TWAUS',
  openAnAccountHero: 'https://open.tastytrade.com/?utm_source=homepage&utm_medium=banner&utm_campaign=you_deserve_better#/login',
  accountSignIn: 'https://my.tastytrade.com/login.html',
  tastyworksBrowserApp: 'https://my.tastytrade.com/',
  bonusBroker: 'https://info.tastyworks.com.au/tasty-offer',
  iosDownload: 'https://itunes.apple.com/us/app/tastyworks/id1276909854?mt=8',
  androidDownload: 'https://play.google.com/store/apps/details?id=com.ig.tastyworks.app',
  worstPasswords: 'https://github.com/danielmiessler/SecLists/blob/master/Passwords/darkweb2017-top1000.txt',
  taxCenter: 'https://my.tastytrade.com/accounts/tax-center',
  finraBrokerCheck: 'https://brokercheck.finra.org/firm/summary/277027',
  supportContact: 'https://support.tastytrade.com/support/s/solutions/articles/43000476860',
  commissionsAndFees: 'https://support.tastytrade.com/support/s/solutions/articles/43000435233',
  futuresContracts: 'https://support.tastytrade.com/support/s/solutions/articles/43000435192',
  smallsFuturesContracts: 'https://support.tastytrade.com/support/s/solutions/articles/43000560059',
  accountTransferForm: 'https://assets.tastyworks.com/production/documents/acat_form.pdf',
  referralFoodPackage: 'https://www.tastesofchicago.com/',
  tastyworksPrivacyPolicy: 'https://assets.tastyworks.com/production/documents/broker_privacy_policy.pdf',
  marginDisclosure: 'https://assets.tastyworks.com/production/documents/margin_disclosure.pdf',
  followFeed: 'https://support.tastytrade.com/support/s/solutions/articles/43000435360',
  futuresHelpCenter: 'https://support.tastytrade.com/support/s/solutions/folders/43000529466',
  freshdeskSupport: 'https://support.tastytrade.com/support/s',
  etradePricing: 'https://us.etrade.com/what-we-offer/pricing-and-rates',
  fidelityPricing: 'https://www.fidelity.com/trading/commissions-margin-rates',
  schwabPricing: 'https://www.schwab.com/public/schwab/investing/pricing_services/fees_minimums',
  tdAmeritradePricing: 'https://www.tdameritrade.com/pricing.page',
  interactiveBrokersPricing: 'https://www.interactivebrokers.com/en/index.php?f=commission&p=options1',
  tastyworksChallenge: 'https://challenge.tastytrade.com/',
  tastyworksTransferAccounts: 'https://info.tastytrade.com/transfer?utm_source=tw_homepage&utm_medium=banners&utm_campaign=Shuffle',
  teslaOffer: 'http://info.tastytrade.com/tesla?utm_source=banner&utm_medium=home_page&utm_campaign=tesla',
  portfolioMargin: 'https://support.tastytrade.com/support/s/solutions/articles/43000463305',
  openAnAccountHero2020: 'https://info.tastytrade.com/you-deserve-better?utm_source=homepage&utm_medium=banner&utm_campaign=better_2020',
  oneHundredShares: 'https://info.tastytrade.com/100shares?utm_source=homepage&utm_medium=banner&utm_campaign=100_shares_2020',
  tradingChallenge2020: 'https://info.tastytrade.com/challenge?utm_source=tw_homepage&utm_medium=banner&utm_campaign=trading_challenge_2020',
  referralProgram: 'https://tastytrade.com/referral/?utm_source=tastyworks&utm_medium=banner&utm_campaign=tastyworks_referral_2020&utm_content=homepage',
  tenOrOneHundred: 'https://info.tastyworks.com.au/10or100?utm_source=tastyworks_AUS&utm_medium=homepage_banner&utm_campaign=10or100_2021',
  bestOnlineBroker2021: 'https://www.investors.com/news/tastyworks-platform-takes-best-online-broker-2021-crown/',
  cryptoMillionGiveaway: 'https://info.tastyworks.com.au/crypto?utm_source=tastyworks_AUS&utm_medium=banner&utm_campaign=crypto_giveaway_2021',
  cryptoKickback: 'https://info.tastytrade.com/kickback?utm_source=homepage&utm_medium=banner&utm_campaign=kickback_2021',
  howToFundInternational: 'https://support.tastytrade.com/support/s/solutions/articles/43000475189',
  asicRegistration: 'https://connectonline.asic.gov.au/RegistrySearch/faces/landing/panelSearch.jspx?searchType=OrgAndBusNm&searchText=623542969',
  asicHome: 'https://connectonline.asic.gov.au/RegistrySearch/faces/landing/ProfessionalRegisters.jspx?_adf.ctrl-state=ar41d5xcf_4',
  productDisclosureStatement: 'https://assets.tastyworks.com/production/documents/broker_australia_margin_loan_product_disclosure_statement.pdf',
  cryptoPromo: 'https://info.tastyworks.com.au/crypto?&utm_source=tastyworks_AUS&utm_medium=homepage_banner&utm_campaign=crypto_2021',
  marginLoan: 'https://www.westpac.com.au/personal-banking/investments/loans/',
  tastyliveLearningCenter: 'https://learn.tastylive.com/',
  upgradeTradingLevels: 'https://support.tastytrade.com/support/s/solutions/articles/43000435240',
  form1042: 'https://support.tastytrade.com/support/s/solutions/articles/430004681131042',
  taxesFaqInternationalAccounts: 'https://support.tastytrade.com/support/s/solutions/articles/43000435356',
  taxReconciliationInternational: 'https://support.tastytrade.com/support/s/solutions/articles/43000650589',
  taxWithholdingInternational: 'https://support.tastytrade.com/support/s/solutions/articles/43000472762',
  consolidated1099: 'https://support.tastytrade.com/support/s/solutions/articles/430004351831099',
  taxCenterLocation: 'https://support.tastytrade.com/support/s/solutions/articles/43000435220',
  taxFormCalendar: 'https://support.tastytrade.com/support/s/solutions/articles/43000435190',
  expectedTaxForm: 'https://support.tastytrade.com/support/s/solutions/articles/43000435208',
  taxFormCorrections: 'https://support.tastytrade.com/support/s/solutions/articles/43000487849',
  taxLots: 'https://support.tastytrade.com/support/s/solutions/articles/43000435250',
  costBasis: 'https://support.tastytrade.com/support/s/solutions/articles/430006154200',
  importDataTurboTax: 'https://support.tastytrade.com/support/s/solutions/articles/43000435239',
  importDataHandR: 'https://support.tastytrade.com/support/s/solutions/articles/43000435367',
  importDataTaxAct: 'https://support.tastytrade.com/support/s/solutions/articles/43000435350',
  importDataTradeLog: 'https://support.tastytrade.com/support/s/solutions/articles/43000435341',
  EinLocation: 'https://support.tastytrade.com/support/s/solutions/articles/43000534729',
  downloadYearToDateData: 'https://support.tastytrade.com/support/s/solutions/articles/430005369591040',
  washSalesDisallowedLoss: 'https://support.tastytrade.com/support/s/solutions/articles/43000435251',
  commissionsAndFeesTaxForm: 'https://support.tastytrade.com/support/s/solutions/articles/43000562421',
  shortTermLongTermContracts: 'https://support.tastytrade.com/support/s/solutions/articles/430004353031256',
  reportSection1256: 'https://support.tastytrade.com/support/s/solutions/articles/430005613481256',
  taxesOnOptionsExercisesAssignmentsAndRolls: 'https://support.tastytrade.com/support/s/solutions/articles/43000652047',
  dividendsTaxed: 'https://support.tastytrade.com/support/s/solutions/articles/43000435305',
  accountOpeningManagementSite: {
    accountMinimums: 'https://support.tastytrade.com/support/s/solutions/articles/43000435184',
    accountTypes: 'https://support.tastytrade.com/support/s/solutions/articles/43000435221',
    applicationProcessingTimes: 'https://support.tastytrade.com/support/s/solutions/articles/43000473089',
    jointAccountOpening: 'https://support.tastytrade.com/support/s/solutions/articles/43000435252',
    openingAnAdditionalAccount: 'https://support.tastytrade.com/support/s/solutions/articles/43000435246',
    howChangeMyAccountType: 'https://support.tastytrade.com/support/s/solutions/articles/43000435403',
    howReopenMyAccount: 'https://support.tastytrade.com/support/s/solutions/articles/43000462597',
    creditCheck: 'https://support.tastytrade.com/support/s/solutions/articles/43000488232',
    trustedContact: 'https://support.tastytrade.com/support/s/solutions/articles/43000502776',
    accountNumberLocation: 'https://support.tastytrade.com/support/s/solutions/articles/43000503722',
    requiredAccountOpeningDocuments: 'https://support.tastytrade.com/en/support/solutions/articles/43000472787',
    howCompleteSubmitW8: 'https://support.tastytrade.com/support/s/solutions/articles/430004727658',
    initialPlatformAccess: 'https://support.tastytrade.com/support/s/solutions/articles/43000513142',
    acceptableVisaTypes: 'https://support.tastytrade.com/support/s/solutions/articles/43000522511',
    liveQuotes: 'https://support.tastytrade.com/support/s/solutions/articles/43000475299',
    incompleteApplication: 'https://support.tastytrade.com/support/s/solutions/articles/43000646863',
    contactUs: 'https://support.tastytrade.com/support/s/solutions/articles/43000476860',
    limitedTradingAuthorization: 'https://support.tastytrade.com/support/s/solutions/articles/43000435200',
    tradeConfirmationAccountStatements: 'https://support.tastytrade.com/support/s/solutions/articles/43000435270',
    closeAccount: 'https://support.tastytrade.com/support/s/solutions/articles/43000435334',
    changingPersonalInformation: 'https://support.tastytrade.com/support/s/solutions/articles/43000435342',
    pushAndEmailNotifications: 'https://support.tastytrade.com/support/s/solutions/articles/43000435381',
    changingUsernames: 'https://support.tastytrade.com/support/s/solutions/articles/43000435388',
    changeEmailAddress: 'https://support.tastytrade.com/support/s/solutions/articles/43000435391',
    paperConfirmationAndStatements: 'https://support.tastytrade.com/support/s/solutions/articles/43000461482',
    mutualFundLiquidation: 'https://support.tastytrade.com/support/s/solutions/articles/43000476880',
    optingOutOfTextNotifications: 'https://support.tastytrade.com/support/s/solutions/articles/43000484780',
    whatIsMyUsername: 'https://support.tastytrade.com/support/s/solutions/articles/43000488162',
    beneficiaryAndTransferOnDeath: 'https://support.tastytrade.com/support/s/solutions/articles/43000500668',
    twoFactorViaAuthenticator: 'https://support.tastytrade.com/support/s/solutions/articles/43000578659',
    twoFactorViaSMS: 'https://support.tastytrade.com/support/s/solutions/articles/43000561685',
    changePassword: 'https://support.tastytrade.com/support/s/solutions/articles/43000435315',
    passwordReset: 'https://support.tastytrade.com/support/s/solutions/articles/43000504479',
    confirmEmailAddress: 'https://support.tastytrade.com/support/s/solutions/articles/43000461275',
    SIPCProtection: 'https://support.tastytrade.com/support/s/solutions/articles/43000435324',
    securityQuestion: 'https://support.tastytrade.com/support/s/solutions/articles/43000435394',
    tradingLevels: 'https://support.tastytrade.com/support/s/solutions/articles/43000435222',
    upgradeTradingLevels: 'https://support.tastytrade.com/support/s/solutions/articles/43000435240'
  },
  withdrawalsAndTransfersSite: {
    transferAccountFromAnotherBroker: 'https://support.tastytrade.com/support/s/solutions/articles/43000435288',
    ACATSFormInstructions: 'https://support.tastytrade.com/support/s/solutions/articles/43000435257',
    electronicallyTransferFromBrokerageAccount: 'https://support.tastytrade.com/support/s/solutions/articles/43000559413',
    remainingCashBalanceStillInOldBrokerageAccount: 'https://support.tastytrade.com/support/s/solutions/articles/43000475307',
    costBasisNotUpdatingAfterAnACATTransfer: 'https://support.tastytrade.com/support/s/solutions/articles/43000475319',
    transferMyTastyworksAccountToAnotherBrokerage: 'https://support.tastytrade.com/support/s/solutions/articles/43000435397',
    depositoryTrustCompanyNumber: 'https://support.tastytrade.com/support/s/solutions/articles/43000502718',
    DRSTransfers: 'https://support.tastytrade.com/support/s/solutions/articles/43000642460',
    transferCashOrPositionsBetweenTastyworksAccounts: 'https://support.tastytrade.com/support/s/solutions/articles/43000435249',
    wireWithdrawalInstructions: 'https://support.tastytrade.com/support/s/solutions/articles/43000435291',
    amountDeductedFromWireDeposit: 'https://support.tastytrade.com/support/s/solutions/articles/43000476885',
    howToFund: 'https://tastyworks.com.au/how-to-fund/',
    viewPendingDepositsOrWithdrawals: 'https://support.tastytrade.com/support/s/solutions/articles/43000460757'
  }
};

import React, { Fragment, useState } from 'react';
import { Link } from 'gatsby';
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';
import get from 'lodash.get';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Logo from 'components/Logo';
import { mainNavItems } from 'utils/headerData';
import AlertBanner from 'components/AlertBanner';
import ContextConsumer from 'components/Context';
import DropdownLink from './components/DropdownLink';
import SuperNavLinks from './components/SuperNavLinks';
import MobileNavbar from './styled/MobileNavbar';
import StyledHeader from './styled/StyledHeader';
import SuperNav from './components/SuperNav';
import LogoLink from './styled/LogoLink';
import NavList from './styled/NavList';

library.add(faChevronRight, faTimes);

const StyledCollapse = styled(Collapse)`
  background-color: ${props => props.theme.colors.black};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    background-color: ${props => props.theme.colors.white};
  }
`;

const SuperNavLinksWrapper = styled.div`
  width: 100%;

  ${ifProp('$hideDesktop', css`
    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      display: none;
    }
  `)}
`;

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [showMobileExternalModal, setShowMobileExternalModal] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const toggleExternalModal = () => {
    setShowMobileExternalModal(!showMobileExternalModal);
  }

  return (
    <StyledHeader>
      <ContextConsumer>
        {({ data }) => (
          <>
            <AlertBanner />
            <SuperNav
              loginData={data}
            />
            <Navbar color='#ffffff' expand='lg'>
              <Container>
                <MobileNavbar>
                  <NavbarToggler
                    className={isOpen ? 'active' : ''}
                    onClick={toggleNav}
                  />
                  <LogoLink tag={Link} className='tasty-logo' to='/'>
                    <Logo showFlag header />
                  </LogoLink>
                </MobileNavbar>
                <StyledCollapse isOpen={isOpen} navbar>
                  {mainNavItems.length > 0 && (
                    <NavList className='ml-auto' navbar>
                      {mainNavItems.map((item, index) => {
                        const itemLink = get(item, 'link.[0]');
                        const itemInternal = get(item, 'link.[0].url');
                        const itemExternal = get(item, 'link.[0].openInNewWindow');

                        return (
                          <Fragment key={item.linkText}>
                            {item.link && !item.specialBehavior && !item.submenu && (
                              <NavItem>
                                <NavLink
                                  className='nav-link'
                                  href={itemExternal ? get(subitem, 'link.[0].linkUrl.href') : 'undefined'}
                                  rel={itemExternal ? 'noopener noreferrer' : ''}
                                  target={itemExternal ? '_blank' : '_self'}
                                  tag={itemExternal ? 'a' : Link}
                                  to={itemInternal || 'undefined'}
                                >
                                  {item.linkText}
                                </NavLink>
                              </NavItem>
                            )}
                            {!item.specialBehavior && item.submenu && (
                              <DropdownLink
                                item={item}
                                index={index}
                                itemLink={itemLink}
                                itemInternal={itemInternal}
                                itemExternal={itemExternal}
                                toggleExternalModal={toggleExternalModal}
                                showMobileExternalModal={showMobileExternalModal}
                                menuType='main'
                              />
                            )}
                          </Fragment>
                        )
                      })}
                      <SuperNavLinksWrapper $hideDesktop>
                        <SuperNavLinks
                          loginData={data}
                        />
                      </SuperNavLinksWrapper>
                    </NavList>
                  )}
                </StyledCollapse>
              </Container>
            </Navbar>
          </>
        )}
      </ContextConsumer>
    </StyledHeader>
  );
}

export default Header;

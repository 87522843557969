import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown
} from 'reactstrap';
import { prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { NAV_BREAKPOINT, DEVICE_VISIBILITY } from 'utils/constants';
import ExternalLink from 'components/ExternalLink';

const Dropdown = styled(UncontrolledDropdown)`
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 100%;
  }

  &.mobile-only {
    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      display: none;
    }
  }

  &.desktop-only {
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      display: none;
    }
  }

  .nav-link {
    &.dropdown-toggle-link {
      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        &:hover,
        &:focus,
        &:active {
          color: ${prop('theme.colors.primaryRed')};
        }
      }
    }
  }

  ${switchProp('$menuType', {
    super: css`
      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        padding: 0.5rem 0;
      }

      .dropdown-menu {
        background-color: ${prop('theme.colors.secondaryBlack')};
        min-width: 14rem;

        @media (max-width: ${props => props.theme.breakpoints.lg}) {
          background-color: ${prop('theme.colors.white')};
        }
      }
    `,
  })}

  .dropdown-menu {
    border: none;
    border-radius: 0;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      position: unset !important;
      transform: none !important;
    }

    .mobile-only {
      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        display: none;
      }
    }

    .desktop-only {
      @media (max-width: ${props => props.theme.breakpoints.lg}) {
        display: none;
      }
    }

    .nav-link {
      @media (max-width: ${props => props.theme.breakpoints.lg}) {
        color: ${prop('theme.colors.black')};

        &:hover,
        &:focus,
        &:active {
          color: ${prop('theme.colors.primaryRed')};
        }
      }

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
`;

DropdownLink.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  itemLink: PropTypes.object,
  itemInternal: PropTypes.string,
  itemExternal: PropTypes.bool,
  menuType: PropTypes.string,
  showMobileExternalModal: PropTypes.bool,
  toggleExternalModal: PropTypes.func,
};

function DropdownLink({
  item,
  index,
  itemLink,
  itemInternal,
  itemExternal = false,
  menuType,
  showMobileExternalModal,
  toggleExternalModal,
}) {
  const [dropdownOpen, toggleDropdownOpen] = useState(false);
  const { width } = useWindowDimensions();
  const mobileNavActive = width <= NAV_BREAKPOINT;

  const hoverOpenCheck = (index) => {
    if (!mobileNavActive) {
      toggleDropdownOpen(index);
    }
  }

  const hoverCloseCheck = () => {
    if (!mobileNavActive) {
      toggleDropdownOpen(false);
    }
  }

  const clickOpenCheck = (index) => {
    if (mobileNavActive) {
      const toggleState = dropdownOpen === index ? false : index;
      toggleDropdownOpen(toggleState);
    }
  }

  const disableClickMobile = (event) => {
    if (mobileNavActive) {
      event.preventDefault();
    }
  }

  return (
    <Dropdown
      className={DEVICE_VISIBILITY[item.deviceVisibility] || ''}
      onClick={() => clickOpenCheck(index)}
      onMouseEnter={() => hoverOpenCheck(index)}
      onMouseLeave={() => hoverCloseCheck()}
      isOpen={dropdownOpen === index}
      $menuType={menuType}
    >
      {itemLink && itemExternal && (
        <DropdownToggle
          className='dropdown-toggle-link'
          href={get(itemLink, 'linkUrl.href')}
          onClick={(event) => disableClickMobile(event)}
          rel='noopener noreferrer'
          target='_blank'
          tag='a'
          nav
        >
          {item.linkText}
        </DropdownToggle>
      )}
      {itemLink && itemInternal && (
        <DropdownToggle
          className='dropdown-toggle-link'
          to={itemInternal}
          onClick={(event) => disableClickMobile(event)}
          tag={Link}
          nav
        >
          {item.linkText}
        </DropdownToggle>
      )}
      {!itemLink && (
        <DropdownToggle nav>
          {item.linkText}
        </DropdownToggle>
      )}
      <DropdownMenu>
        {item.submenu.map((subitem, subIndex) => {
          const externalSubitem = get(subitem, 'link.[0].openInNewWindow');
          const externalModal = get(subitem, 'link.[0].showExternalLinkPopup');
          const internalSubitem = get(subitem, 'link.[0].url');
          const disclaimerPresent = get(subitem, 'link.[0].disclaimerPresent', false)

          return (
            <NavItem
              className={DEVICE_VISIBILITY[subitem.deviceVisibility] || ''}
              key={subIndex}
            >
              {externalModal ? (
                <ExternalLink
                  className='nav-link dropdown-link'
                  displayUrl='http://tastytrade.com'
                  mobileNavMenu={mobileNavActive}
                  href={get(subitem, 'link.[0].linkUrl.href')}
                  showMobileExternalModal={showMobileExternalModal}
                  toggleExternalModal={toggleExternalModal}
                  disclaimerPresent={disclaimerPresent}
                >
                  {subitem.linkText}
                </ExternalLink>
              ) : (
                <NavLink
                  className='nav-link dropdown-link'
                  href={externalSubitem ? get(subitem, 'link.[0].linkUrl.href') : 'undefined'}
                  rel={externalSubitem ? 'noopener noreferrer' : ''}
                  target={externalSubitem ? '_blank' : '_self'}
                  tag={externalSubitem ? 'a' : Link}
                  to={internalSubitem || 'undefined'}
                >
                  {subitem.linkText}
                </NavLink>
              )}
            </NavItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export default DropdownLink;

import React, { Fragment } from 'react';
import get from 'lodash.get';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavItem, NavLink } from 'reactstrap';
import OpenAnAccount from 'components/Header/components/OpenAnAccount';
import Login from 'components/Header/components/Login';
import { DEVICE_VISIBILITY } from 'utils/constants';
import { superNavItems } from 'utils/headerData';
import { isAbsoluteUrl } from 'utils/linkResolver';
import linkResolver from 'utils/linkResolver';
import DropdownLink from './DropdownLink';

const NavItemNoSubmenu = styled(NavItem)`
  &.mobile-only {
    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      display: none;
    }
  }

  &.desktop-only {
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      display: none;
    }
  }
`;

SuperNavLinks.propTypes = {
  loginData: PropTypes.shape({
    authenticated: PropTypes.bool,
  }),
};

function SuperNavLinks({ loginData }) {
  return (
    <>
      {superNavItems.map((item, index) => {
        const itemLink = get(item, 'link.[0]');
        const isAbsolute = isAbsoluteUrl(linkResolver(item.link));
        const itemInternal = get(item, 'link.[0].url');
        const itemExternal = get(item, 'link.[0].openInNewWindow') || isAbsolute;

        return (
          <Fragment key={index}>
            {item.link && !item.specialBehavior && !item.submenu && (
              <NavItemNoSubmenu className={DEVICE_VISIBILITY[item && item.deviceVisibility]}>
                <NavLink
                  className='nav-link'
                  component={itemExternal ? 'a' : Link}
                  link={item.link}
                >
                  {item.linkText}
                </NavLink>
              </NavItemNoSubmenu>
            )}
            {!item.specialBehavior && item.submenu && (
              <DropdownLink
                item={item}
                index={index}
                itemLink={itemLink}
                itemInternal={itemInternal}
                itemExternal={itemExternal}
                menuType='super'
              />
            )}
            {item.specialBehavior === 'log_in' && (
              <Login
                data={loginData}
                item={item}
                itemInternal={itemInternal}
                itemExternal={itemExternal}
              />
            )}
            {item.specialBehavior === 'open_an_account' && (
              <OpenAnAccount data={loginData} item={item} />
            )}
          </Fragment>
        )
      })}
    </>
  );
}

export default SuperNavLinks;

const theme = {
  colors: {
    black: '#000',
    primaryBlack: '#202525',
    secondaryBlack: '#212626',
    tertiaryBlack: '#222',
    blackGray: '#333',
    transparentBlack: 'rgba(0, 0, 0, 0.1)',
    darkTransparentBlack: 'rgba(0, 0, 0, 0.4)',
    primaryRed: '#e02529',
    secondaryRed: '#dd2528',
    tertiaryRed: '#e2252a',
    lightRed: '#f6413b',
    darkRed: '#ba1f21',
    darkerRed: '#a51c1e',
    primaryRedTransparent: 'rgba(224, 37, 41, 0.7)',
    secondaryRedTransparent: 'rgba(221, 37, 40, 0.6)',
    darkerRedTransparent: 'rgba(165, 28, 30, 0.5)',
    lightPink: '#ffdede',
    white: '#fff',
    primaryTransparentWhite: 'hsla(0, 0%, 100%, 0.3)',
    secondaryTransparentWhite: 'hsla(0, 0%, 100%, 0.85)',
    primaryOffWhite: '#ededed',
    secondaryOffWhite: '#f8f8f8',
    darkerGray: '#444444',
    darkGray: '#707070',
    slateGray: '#758696',
    gray: '#838a8a',
    lightGray: '#b2b8b8',
    lighterGray: '#ddd',
    lightestGray: '#f3f3f3',
    smokeGray: '#aaaaaa',
    transparentGray: 'rgba(255, 255, 255, 0.4)',
    brightGreen: '#58fe09',
    cyanBlue: '#04eaff',
    lightBlue: '#7de5ea',
    lightNavyBlue: '#3898ec',
    lighterNavyBlue: '#5d93eb',
    skyBlue: '#7de2ff',
    navyBlue: '#0082f3',
    seaGreen: '#52dca8',
    grayscaleGreen: '#c8cbc8',
    darkGrayscaleGreen: '#999998',
    turquoise: '#67d8cd',
    grayscaleBlue: '#ebeded',
    promoPrimaryGray: '#b2b8b8',
    promoSecondaryGray: '#bcc3c3',
    mustardYellow: '#ffc03a',
    cryptoYellow: '#dbae4b',
    success: '#668a59',
  },
  breakpoints: {
    xxs: '320px',
    xs: '479px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
  buttons: {
    default: {
      color: '#fff',
      backgroundColor: '#000',
    },
    primary: {
      color: '#fff',
      backgroundColor: '#e02529',
    },
    header: {
      color: '#fff',
      backgroundColor: 'linear-gradient(#e02529, #dd2528, #a51c1e)',
    },
    footer: {
      color: '#fff',
      backgroundColor: 'linear-gradient(#e02529, #dd2528, #a51c1e)',
    },
  },
  fonts: {
    default: '"Open Sans", sans-serif',
    condensed: '"Open Sans Condensed", sans-serif',
  },
};

export default theme;

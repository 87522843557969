import styled from 'styled-components';

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0.125rem 0.125rem 0.1875rem;
  width: 100%;
  z-index: 1000;
  background: ${props => props.theme.colors.white};

  .container {
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      max-width: none;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }
  }

  .navbar {
    padding: 0;
  }
`;

export default StyledHeader;

const absoluteUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');

export function isAbsoluteUrl(url) {
  return absoluteUrlRegex.test(url);
}

export default function linkResolver(link) {
  const linkDetails = link[0] || {};
  const { url, linkUrl } = linkDetails;

  return linkUrl && linkUrl.href ? linkUrl.href : url;
}
import { NavbarBrand } from 'reactstrap';
import styled from 'styled-components';

const LogoLink = styled(NavbarBrand)`
  text-decoration: none;
  padding-bottom: 0 !important;
  padding-top: 0.7125rem !important;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 60%;
  }
  
  &:hover {
    text-decoration: none;
    
    small {
      color: ${props => props.theme.colors.darkGray};
    }
  }

  small {
    color: ${props => props.theme.colors.secondaryBlack};
    display: block;
    font-size: 0.875rem;
  }
`;

export default LogoLink;

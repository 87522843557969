import React from 'react';
import PropTypes from 'prop-types';
import { Container, Nav, Navbar } from 'reactstrap';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import TastyButton from 'components/TastyButton';
import SuperNavLinks from './SuperNavLinks';

const SuperNavbar = styled(Navbar)`
  background-color: ${prop('theme.colors.secondaryBlack')};

  &.navbar {
    display: none;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      display: block;
    }
  }

  .container {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const NavList = styled(Nav)`
  align-items: center;
  display: flex;

  .nav-item {
    &.mobile {
      display: list-item;

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        display: none;
      }
    }

    a {
      &:hover {
        color: ${props => props.theme.colors.primaryRed};
      }
    }
  }

  .nav-link {
    color: ${prop('theme.colors.white')};
    font-family: ${prop('theme.fonts.default')};
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      margin: 0 0.5em;
    }
  }

  ${TastyButton} {
    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      margin-left: 1em;
    }
  }
`;

SuperNav.propTypes = {
  loginData: PropTypes.shape({
    authenticated: PropTypes.bool,
  }),
};

function SuperNav({ loginData }) {
  return (
    <SuperNavbar expand='lg'>
      <Container>
        <NavList className='ml-auto' navbar>
          <SuperNavLinks
            loginData={loginData}
          />
        </NavList>
      </Container>
    </SuperNavbar>
  );
}

export default SuperNav;

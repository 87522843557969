import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'reactstrap';
import TastyModal from './TastyModal';
import TastyButton from './TastyButton';

const propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
  displayUrl: PropTypes.string,
  as: PropTypes.elementType,
  mobileNavMenu: PropTypes.bool,
  showMobileExternalModal: PropTypes.bool,
  toggleExternalModal: PropTypes.func,
};

const defaultProps = {
  as: 'a',
  children: undefined
};

function getHostName(url) {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
    return match[2];
  }

  return null;
}

function ExternalLink ({
  href,
  displayUrl,
  children,
  as: LinkComponent,
  mobileNavMenu,
  showMobileExternalModal,
  toggleExternalModal,
  disclaimerPresent = false,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (e) => {
    e.preventDefault();

    if (mobileNavMenu) {
      toggleExternalModal();
    } else {
      setIsOpen(prev => !prev);
    }
  };

  return (
    <>
      <LinkComponent href={href} onClick={toggle} {...props}>{children}</LinkComponent>

      <TastyModal isOpen={mobileNavMenu ? showMobileExternalModal : isOpen} toggle={toggle} title="Leaving?">
        <ModalBody>
          You are now leaving tastytrade.au and heading to {getHostName(href) || href}.
          {disclaimerPresent ? <>
            <div><br/>Note: Not all products and services may be available to Australian residents.</div>
            <br/>
          </> : ' '}
          <strong>Do you want to continue?</strong>

        </ModalBody>
        <ModalFooter>
          <TastyButton onClick={toggle}>No</TastyButton>
          <TastyButton primary href={href} target="_blank">Yes</TastyButton>
        </ModalFooter>
      </TastyModal>
    </>
  );
}

ExternalLink.propTypes = propTypes;
ExternalLink.defaultProps = defaultProps;
export default ExternalLink

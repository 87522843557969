import externalLinks from 'utils/externalLinks';

export const mainNavItems = [
  {
    linkText: 'Pricing',
    submenu: [
      {
        linkText: 'Our Pricing',
        link: [{
          openInNewWindow: true,
          showExternalLinkPopup: true,
          linkUrl: {
            href: 'https://tastytrade.com/pricing/',
          },
        }],
      },
      {
        linkText: 'Commissions & Fees',
        link: [{
          openInNewWindow: true,
          showExternalLinkPopup: true,
          disclaimerPresent: true,
          linkUrl: {
            href: 'https://tastytrade.com/commissions-and-fees/'
          },
        }],
      },
    ]
  },
  {
    linkText: 'Accounts',
    submenu: [
      {
        linkText: 'Account Types',
        link: [{
          url: '/accounts/'
        }],
      },
      {
        linkText: 'How to Fund',
        link: [{
          url: '/how-to-fund/'
        }],
      },
    ]
  },
  {
    linkText: 'Platforms',
    submenu: [
      {
        linkText: 'Our Technology',
        link: [{
          url: '/technology/'
        }],
      }
    ]
  },
  {
    linkText: 'Trading Inspiration',
    submenu: [
      {
        linkText: 'Education & Content',
        link: [{
          url: '/education/',
        }],
      }
    ]
  },
  {
    linkText: 'Contact Us',
    openInNewWindow: false,
    showExternalLinkPopup: false,
    link: [{
      url: '/contact-us/',
    }]
  },
];

export const superNavItems = [
  {
    link: [{
      openInNewWindow: true,
      showExternalLinkPopup: false,
      linkUrl: {
        href: externalLinks.freshdeskSupport
      },
    }],
    itemExternal: true,
    linkText: 'Help Center',
    submenu: [
      {
        deviceVisibility: 'mobile_only',
        link: [{
          openInNewWindow: true,
          showExternalLinkPopup: false,
          linkUrl: {
            href: externalLinks.freshdeskSupport
          }
        }],
        linkText: 'Help Center Home'
      },
      {
        link: [{
          openInNewWindow: false,
          showExternalLinkPopup: false,
          url: '/how-to-fund/'
        }],
        linkText: 'Deposit & Funding'
      },
      {
        link: [{
          openInNewWindow: false,
          showExternalLinkPopup: false,
          url: '/withdrawals-and-transfers/'
        }],
        linkText: 'Withdrawals & Transfers'
      },
      {
        link: [{
          openInNewWindow: false,
          showExternalLinkPopup: false,
          url: '/account-opening-management/'
        }],
        linkText: 'Account Opening & Management'
      },
      {
        link: [{
          openInNewWindow: true,
          showExternalLinkPopup: false,
          linkUrl: {
            href: 'https://support.tastytrade.com/support/s/solutions/43000357785',
          }
        }],
        linkText: 'Getting Started'
      },
      {
        link: [{
          openInNewWindow: true,
          showExternalLinkPopup: false,
          linkUrl: {
            href: 'https://support.tastytrade.com/support/s/solutions/43000224176',
          }
        }],
        linkText: 'Platforms & Tech Support'
      },
      {
        link: [{
          openInNewWindow: true,
          showExternalLinkPopup: false,
          linkUrl: {
            href: 'https://support.tastytrade.com/support/s/solutions/43000224174',
          }
        }],
        linkText: 'Trading, Buying Power, & Margin'
      },
    ]
  },
  {
    link: [{
      openInNewWindow: false,
      showExternalLinkPopup: false,
      linkUrl: {
        href: externalLinks.accountSignIn,
      }
    }],
    linkText: 'Log In',
    specialBehavior: 'log_in'
  },
  {
    link: [{
      openInNewWindow: false,
      showExternalLinkPopup: false,
      linkUrl: {
        href: externalLinks.openAnAccount,
      }
    }],
    linkText: 'Open an Account',
    specialBehavior: 'open_an_account'
  }
]

import { createGlobalStyle } from 'styled-components';
import { prop } from 'styled-tools'
import fontBsBoldWoff from 'fonts/bentonsans-bold-webfont.woff';
import fontBsBoldWoff2 from 'fonts/bentonsans-bold-webfont.woff2';
import fontBsBookWoff from 'fonts/bentonsans-book-webfont.woff';
import fontBsBookWoff2 from 'fonts/bentonsans-book-webfont.woff2';
import fontBsExtraLightWoff from 'fonts/bentonsans-extralight-webfont.woff';
import fontBsExtraLightWoff2 from 'fonts/bentonsans-extralight-webfont.woff2';
import fontBsLightWoff from 'fonts/bentonsans-light-webfont.woff';
import fontBsLightWoff2 from 'fonts/bentonsans-light-webfont.woff2';
import fontBsMediumWoff from 'fonts/bentonsans-medium-webfont.woff';
import fontBsMediumWoff2 from 'fonts/bentonsans-medium-webfont.woff2';
import fontBsRegularWoff from 'fonts/bentonsans-regular-webfont.woff';
import fontBsRegularWoff2 from 'fonts/bentonsans-regular-webfont.woff2';
import fontBsCondLightWoff from 'fonts/bentonsanscond-light-webfont.woff';
import fontBsCondLightWoff2 from 'fonts/bentonsanscond-light-webfont.woff2';
import fontBsCondBookWoff from 'fonts/bentonsanscond-book-webfont.woff';
import fontBsCondBookWoff2 from 'fonts/bentonsanscond-book-webfont.woff2';
import fontBsCondRegularWoff from 'fonts/bentonsanscond-regular-webfont.woff';
import fontBsCondRegularWoff2 from 'fonts/bentonsanscond-regular-webfont.woff2';
import fontBsCondMediumWoff from 'fonts/bentonsanscond-medium-webfont.woff';
import fontBsCondMediumWoff2 from 'fonts/bentonsanscond-medium-webfont.woff2';
import fontBsCondBoldWoff from 'fonts/bentonsanscond-bold-webfont.woff';
import fontBsCondBoldWoff2 from 'fonts/bentonsanscond-bold-webfont.woff2';
import fontBsCondBlackWoff from 'fonts/bentonsanscond-black-webfont.woff';
import fontBsCondBlackWoff2 from 'fonts/bentonsanscond-black-webfont.woff2';
import fontsBsExtraCompBlackWoff from 'fonts/BentonSansExtraComp-Black.woff';
import fontsBsExtraCompBlackWoff2 from 'fonts/BentonSansExtraComp-Black.woff2';
import fontBsExtraCompBoldWoff from 'fonts/BentonSansExtraComp-Bold.woff';
import fontsBsExtraCompBoldWoff2 from 'fonts/BentonSansExtraComp-Bold.woff2';
import fontsBsExtraCompBookWoff from 'fonts/BentonSansExtraComp-Book.woff';
import fontsBsExtraCompBookWoff2 from 'fonts/BentonSansExtraComp-Book.woff2';
import fontsBsExtraCompLightWoff from 'fonts/BentonSansExtraComp-Light.woff';
import fontsBsExtraCompLightWoff2 from 'fonts/BentonSansExtraComp-Light.woff2';
import fontsBsExtraCompMediumWoff from 'fonts/BentonSansExtraComp-Medium.woff';
import fontsBsExtraCompMediumWoff2 from 'fonts/BentonSansExtraComp-Medium.woff2';
import fontsBsExtraCompRegularWoff from 'fonts/BentonSansExtraComp-Regular.woff';
import fontsBsExtraCompRegularWoff2 from 'fonts/BentonSansExtraComp-Regular.woff2';
import fontHeeboVariableWeightWoff from 'fonts/Heebo-VariableFont_wght.woff';
import fontHeeboVariableWeightWoff2 from 'fonts/Heebo-VariableFont_wght.woff2';
import fontBeVietnamLigthWoff from 'fonts/BeVietnam-Light.woff';
import fontBeVietnamLightWoff2 from 'fonts/BeVietnam-Light.woff2';
import fontBeVietnamRegularWoff from 'fonts/BeVietnam-Regular.woff';
import fontBeVietnamRegularWoff2 from 'fonts/BeVietnam-Regular.woff2';
import fontBeVietnamBoldWoff from 'fonts/BeVietnam-Bold.woff';
import fontBeVietnamBoldWoff2 from 'fonts/BeVietnam-Bold.woff2';
import fontRobotoRegularWoff from 'fonts/Roboto-Regular.woff';
import fontRobotoRegularWoff2 from 'fonts/Roboto-Regular.woff2';
import fontRobotoBoldWoff from 'fonts/Roboto-Bold.woff';
import fontRobotoBoldWoff2 from 'fonts/Roboto-Bold.woff2';
import fontHeeboLightWoff from 'fonts/Heebo-Light.woff';
import fontHeeboLightWoff2 from 'fonts/Heebo-Light.woff2';
import fontMondaRegularWoff from 'fonts/Monda-Regular.woff';
import fontMondaRegularWoff2 from 'fonts/Monda-Regular.woff2';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'benton-sans';
    src: url('${fontBsBoldWoff}') format('woff'),
    url('${fontBsBoldWoff2}') format('woff2');
    font-weight: 700;
    font-style: normal;
  }

   @font-face {
    font-family: 'benton-sans';
    src: url('${fontBsBookWoff}') format('woff'),
    url('${fontBsBookWoff2}') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

   @font-face {
    font-family: 'benton-sans';
    src: url('${fontBsExtraLightWoff}') format('woff'),
    url('${fontBsExtraLightWoff2}') format('woff2');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans';
    src: url('${fontBsLightWoff}') format('woff'),
    url('${fontBsLightWoff2}') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans';
    src: url('${fontBsMediumWoff}') format('woff'),
    url('${fontBsMediumWoff2}') format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans';
    src: url('${fontBsRegularWoff}') format('woff'),
    url('${fontBsRegularWoff2}') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans-condensed';
    src: url('${fontBsCondLightWoff}') format('woff'),
    url('${fontBsCondLightWoff2}') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans-condensed';
    src: url('${fontBsCondBookWoff}') format('woff'),
    url('${fontBsCondBookWoff2}') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans-condensed';
    src: url('${fontBsCondRegularWoff}') format('woff'),
    url('${fontBsCondRegularWoff2}') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

   @font-face {
    font-family: 'benton-sans-condensed';
    src: url('${fontBsCondMediumWoff}') format('woff'),
    url('${fontBsCondMediumWoff2}') format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans-condensed';
    src: url('${fontBsCondBoldWoff}') format('woff'),
    url('${fontBsCondBoldWoff2}') format('woff2');
    font-weight: 700;
    font-style: normal;
  }

   @font-face {
    font-family: 'benton-sans-condensed';
    src: url('${fontBsCondBlackWoff}') format('woff'),
    url('${fontBsCondBlackWoff2}') format('woff2');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans-extra-compressed';
    src: url('${fontsBsExtraCompBlackWoff}') format('woff'),
    url('${fontsBsExtraCompBlackWoff2}') format('woff2');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans-extra-compressed';
    src: url('${fontBsExtraCompBoldWoff}') format('woff'),
    url('${fontsBsExtraCompBoldWoff2}') format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans-extra-compressed';
    src: url('${fontsBsExtraCompBookWoff}') format('woff'),
    url('${fontsBsExtraCompBookWoff2}') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans-extra-compressed';
    src: url('${fontsBsExtraCompLightWoff}') format('woff'),
    url('${fontsBsExtraCompLightWoff2}') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans-extra-compressed';
    src: url('${fontsBsExtraCompMediumWoff}') format('woff'),
    url('${fontsBsExtraCompMediumWoff2}') format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'benton-sans-extra-compressed';
    src: url('${fontsBsExtraCompRegularWoff}') format('woff'),
    url('${fontsBsExtraCompRegularWoff2}') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'be-vietnam';
    src: url('${fontBeVietnamLigthWoff}') format('woff'),
    url('${fontBeVietnamLightWoff2}') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'be-vietnam';
    src: url('${fontBeVietnamRegularWoff}') format('woff'),
    url('${fontBeVietnamRegularWoff2}') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'be-vietnam';
    src: url('${fontBeVietnamBoldWoff}') format('woff'),
    url('${fontBeVietnamBoldWoff2}') format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'roboto';
    src: url('${fontRobotoRegularWoff}') format('woff'),
    url('${fontRobotoRegularWoff2}') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'roboto';
    src: url('${fontRobotoBoldWoff}') format('woff'),
    url('${fontRobotoBoldWoff2}') format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'heebo';
    src: url('${fontHeeboLightWoff}') format('woff'),
    url('${fontHeeboLightWoff2}') format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Monda';
    src: url('${fontMondaRegularWoff}') format('woff'),
    url('${fontMondaRegularWoff2}') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  body {
    font-family: ${prop('theme.fonts.default')};
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: ${prop('theme.fonts.condensed')};
  }

  a {
    color: ${props => props.theme.colors.black};
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      color: ${props => props.theme.colors.black};
    }
  }

  p {
    font-family: ${prop('theme.fonts.default')};
    font-weight: 400;
  }

  .modal {
    &.referral-modal {
      height: 70%;
      top: 8rem;

      @media (max-width: ${prop('theme.breakpoints.xs')}) {
        top: 6.5rem;
      }
    }
  }
`;

export default GlobalStyle;

import React from 'react';
import PropTypes from 'prop-types';
import { NavItem, NavLink } from 'reactstrap';
import externalLinks from 'utils/externalLinks';
import TastyButton from 'components/TastyButton';

OpenAnAccount.propTypes = {
  data: PropTypes.shape({
    authenticated: PropTypes.bool,
  }),
  item: PropTypes.object,
};

function OpenAnAccount({ data, item }) {
  return (
    <>
      <NavItem className='mobile'>
        {data.authenticated ? (
          <a className='nav-link' href={externalLinks.accountSignIn}>
            My Account
          </a>
        ) : (
          <NavLink className='nav-link' href={externalLinks.openAnAccount}>
            {item.linkText}
          </NavLink>
        )}
      </NavItem>
      {data.authenticated ? (
        <TastyButton authenticated header href={externalLinks.accountSignIn}>
          My Account
        </TastyButton>
      ) : (
        <TastyButton header href={externalLinks.openAnAccount}>
          Open an Account
        </TastyButton>
      )}
    </>
  );
}

export default OpenAnAccount;
